import { Doughnut } from "vue-chartjs";
import doughnutlabel from "chartjs-plugin-doughnutlabel";
import datalabels from "chartjs-plugin-datalabels";

export default {
    extends: Doughnut,
    props: {
    dataObject: {
        type: Object,
        default: null,
    },
    },
    data() {
    return {
        imageBytes: "",
        totalSize: null,
        totalTime: null,
        totalReqs: null,
    };
    },
    mounted() {
    // #### adding the correct unit to the totals
    if (this.dataObject.total.size >= 1000) {
        this.totalSize = (this.dataObject.total.size / 1000).toFixed(2) + "KB";
        if (this.dataObject.total.size >= 1000000) {
        this.totalSize =
            (this.dataObject.total.size / 1000000).toFixed(2) + "MB";
        }
    } else {
        this.totalSize = this.dataObject.total.size.toFixed(2) + " Bytes";
    }
    if (this.dataObject.total.time >= 1000) {
        this.totalTime = (this.dataObject.total.time / 1000).toFixed(2) + " sec";
    } else {
        this.totalTime = this.dataObject.total.time.toFixed(2) + "ms";
    }
    this.totalReqs = this.dataObject.total.reqs + " Requests";
    //  ###

    // #### adding the plugins
    this.addPlugin(datalabels);
    this.addPlugin(doughnutlabel);
    // ###
    // #### the function to render the chart
    this.renderChart(
        {
        labels: ["HTML", "JS", "CSS", "Media", "Font", "Other"],
        datasets: [
            {
            label: "Size",
            data: [
                this.dataObject.html.size.toFixed(2),
                this.dataObject.js.size.toFixed(2),
                this.dataObject.css.size.toFixed(2),
                this.dataObject.medias.size.toFixed(2),
                this.dataObject.font.size.toFixed(2),
                this.dataObject.other.size.toFixed(2),
            ],
            backgroundColor: [
                "#D0B2D2",
                "#FFCF70",
                "#AFC185",
                "#A6D5DD",
                "#FFBE85",
                "#FFAC99",
            ],
            datalabels: {
                formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                    sum += Number(data);
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage;
                },
                anchor: "center",
                color: "white",
                backgroundColor: function(context) {
                return context.dataset.backgroundColor;
                },
                borderColor: "white",
                borderRadius: 25,
                borderWidth: 2,
                display: true,
                font: {
                weight: "bold",
                family: "'Titillium Web',sans-serif",
                },
                padding: 10,
            },
            },
            {
            label: "time",
            data: [
                this.dataObject.html.time.toFixed(2),
                this.dataObject.js.time.toFixed(2),
                this.dataObject.css.time.toFixed(2),
                this.dataObject.medias.time.toFixed(2),
                this.dataObject.font.time.toFixed(2),
                this.dataObject.other.time.toFixed(2),
            ],
            backgroundColor: [
                "#D0B2D2",
                "#FFCF70",
                "#AFC185",
                "#A6D5DD",
                "#FFBE85",
                "#FFAC99",
            ],
            datalabels: {
                formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[1].data;
                dataArr.map((data) => {
                    sum += Number(data);
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage;
                },
                anchor: "center",
                color: "white",
                backgroundColor: function(context) {
                return context.dataset.backgroundColor;
                },
                borderColor: "white",
                borderRadius: 25,
                borderWidth: 2,
                display: true,
                font: {
                weight: "bold",
                family: "'Titillium Web',sans-serif",
                },
                padding: 5,
            },
            },
            {
            label: "reqs",
            data: [
                this.dataObject.html.reqs,
                this.dataObject.js.reqs,
                this.dataObject.css.reqs,
                this.dataObject.medias.reqs,
                this.dataObject.font.reqs,
                this.dataObject.other.reqs,
            ],
            backgroundColor: [
                "#D0B2D2",
                "#FFCF70",
                "#AFC185",
                "#A6D5DD",
                "#FFBE85",
                "#FFAC99",
            ],
            datalabels: {
                formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[2].data;
                dataArr.map((data) => {
                    sum += Number(data);
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage;
                },
                anchor: "center",
                color: "white",
                backgroundColor: function(context) {
                return context.dataset.backgroundColor;
                },
                borderColor: "white",
                borderRadius: 25,
                borderWidth: 2,
                display: true,
                font: {
                weight: "bold",
                family: "'Titillium Web',sans-serif",
                },
                padding: 5,
            },
            },
        ],
        },
        {
        //   #### Plugins settings
        plugins: {
            doughnutlabel: {
            labels: [
                {
                text: "Total",
                font: {
                    size: "40",
                    family: "'Titillium Web',sans-serif",
                },
                },
                {
                text: this.totalSize,
                font: {
                    size: "30",
                    family: "'Titillium Web',sans-serif",
                },
                color: "grey",
                },
                {
                text: this.totalReqs,
                font: {
                    size: "30",
                    family: "'Titillium Web',sans-serif",
                },
                color: "grey",
                },
            ],
            },
        },
        responsive: true,
        showTooltips: true,

        tooltips: {
            callbacks: {
            title: function(tooltipItem, data) {
                return data["labels"][tooltipItem[0]["index"]];
            },
            label: function(tooltipItem, data) {
                if (tooltipItem.datasetIndex === 0) {
                if (data["datasets"][0]["data"][tooltipItem["index"]] >= 1000) {
                    if (
                    data["datasets"][0]["data"][tooltipItem["index"]] >= 1000000
                    ) {
                    let size = (
                        data["datasets"][0]["data"][tooltipItem["index"]] /
                        1000000
                    ).toFixed(2);
                    return size + "MB";
                    } else {
                    let size = (
                        data["datasets"][0]["data"][tooltipItem["index"]] / 1000
                    ).toFixed(2);
                    return size + "KB";
                    }
                } else {
                    return (
                    data["datasets"][0]["data"][tooltipItem["index"]] + " Bytes"
                    );
                }
                } else if (tooltipItem.datasetIndex === 1) {
                if (data["datasets"][1]["data"][tooltipItem["index"]] >= 1000) {
                    let time = (
                    data["datasets"][1]["data"][tooltipItem["index"]] / 1000
                    ).toFixed(2);
                    return time + "sec";
                } else {
                    return (
                    data["datasets"][1]["data"][tooltipItem["index"]] + " ms"
                    );
                }
                } else if (tooltipItem.datasetIndex === 2) {
                return (
                    data["datasets"][2]["data"][tooltipItem["index"]] +
                    " Requests"
                );
                }
            },
            },
            backgroundColor: "#FFF",
            titleFontSize: 16,
            titleFontColor: "#0066ff",
            bodyFontColor: "#000",
            bodyFontSize: 14,
            displayColors: false,
        },
        }
    );
    },
};
